import { useCallback, useEffect, useState } from "react";

export function useLocalStorageState<T>(key: string, initialValue: T) {
  const [storedValue, setStoredVaue] = useState(() => {
    const item = window.localStorage.getItem(key);
    return item ? (JSON.parse(item) as T) : initialValue;
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(storedValue));
  }, [key, storedValue]);

  const setValue = useCallback(
    (value: T) => {
      setStoredVaue(value);
      window.localStorage.setItem(key, JSON.stringify(value));
    },
    [key]
  );

  return [storedValue, setValue] as const;
}
