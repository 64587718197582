export const isDevelopement = () => process.env.NODE_ENV === "development";

export const isStaging = () =>
  process.env.NODE_ENV === "production" &&
  //@ts-ignore
  window.REACT_APP_ENVIRONMENT === "Staging";

export const isProduction = () =>
  process.env.NODE_ENV === "production" &&
  //@ts-ignore
  window.REACT_APP_ENVIRONMENT === "Production";

export const apiUrl = !isDevelopement()
  ? `https://api.${(window as any).REACT_APP_BASE_HOST}`
  : "http://localhost:5000";
