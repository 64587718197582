import { useCallback } from "react";
import {
  QueryKey,
  useQueries,
  useQueryClient,
  hashQueryKey,
} from "react-query";
import {
  GroupBase,
  MultiValue,
  OptionsOrGroups,
  SingleValue,
} from "react-select";

export const optionsKey = <TQueryKey extends QueryKey = QueryKey>(
  queryKey: TQueryKey
) =>
  [...(queryKey instanceof Array ? queryKey : [queryKey]), "options"] as const;

export function useAsyncPaginateSelectQuery<
  TQuery,
  TValue,
  IsMulti extends boolean,
  TOption extends { value: TValue },
  TGroup extends GroupBase<TOption> = GroupBase<TOption>,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  isMulti: IsMulti,
  value: IsMulti extends true ? TValue[] : TValue,
  getItem: (value: NonNullable<TValue>) => Promise<TQuery>,
  getItemsPage: (
    search: string,
    offset: number
  ) => Promise<{ items: TQuery[]; hasMoreItems: boolean }>,
  mapToOption: (item: TQuery) => TOption
) {
  const queryClient = useQueryClient();

  const optionQueries = useQueries(
    (value instanceof Array ? value : [value])
      .filter((v) => !!v)
      .map((v) => ({
        queryKey: [...optionsKey(queryKey), v],
        queryFn: async () => {
          const selected = await getItem(v);
          return mapToOption(selected);
        },
      }))
  );

  const isSelectedLoading = optionQueries.some((o) => o.isLoading);
  const selectedOptions =
    optionQueries.length === 0 || isSelectedLoading
      ? isMulti
        ? []
        : undefined
      : isMulti
      ? optionQueries.filter((o) => !!o.data).map((o) => o.data!) ?? []
      : optionQueries[0].data;

  const loadOptions = useCallback(
    async (search: string, loadedOptions: OptionsOrGroups<TOption, TGroup>) => {
      const offset = loadedOptions.length;

      var res = await queryClient.fetchQuery(
        [...optionsKey(queryKey), { search, offset }],
        () => getItemsPage(search, offset)
      );

      var options = res.items.map(mapToOption);

      for (const option of options) {
        queryClient.setQueryData(
          [...optionsKey(queryKey), option.value],
          option
        );
      }

      return {
        options,
        hasMore: res.hasMoreItems,
      };
    },
    [queryClient, queryKey, mapToOption, getItemsPage]
  );

  return {
    loadOptions,
    // react-select does not call `loadOptions` again when it changes,
    // therefore force the reload of options if `queryKey` changes
    // by changing the component `key`
    key: hashQueryKey(queryKey),
    value: selectedOptions,
    isLoading: isSelectedLoading || undefined,
    isMulti,
  };
}

export function mapToValue<TValue, IsMulti extends boolean>(
  isMulti: IsMulti,
  optionOrOptions: IsMulti extends true
    ? MultiValue<{ value: TValue }>
    : SingleValue<{ value: TValue }>
) {
  if (isMulti) {
    return (optionOrOptions as MultiValue<{ value: TValue }>).map(
      (v) => v.value
    );
  } else {
    return (optionOrOptions as SingleValue<{ value: TValue }>)?.value;
  }
}
