import { useLingui } from "@lingui/react";
import { Locale } from "date-fns";
import { it, enUS as en } from "date-fns/locale";

export const supportedDateLocales: { [key: string]: Locale } = {
  it,
  en,
};

export function useSupportedDateLocale() {
  const {
    i18n: { locale },
  } = useLingui();

  return supportedDateLocales[locale.slice(0, 2)] ?? supportedDateLocales["en"];
}
