import { i18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import { format as formatDate, formatDistance } from "date-fns";
import React from "react";
import { supportedDateLocales } from "./useSupportedDateLocale";

const detectedLocale = navigator.language || (navigator as any).userLanguage;

export async function activateDetectedLocale() {
  await activateLocale(detectedLocale);
  document.documentElement.lang = detectedLocale;
}

export async function activateLocale(locale: string) {
  let sanitizedLocale;

  if (/^it-?/i.test(locale)) {
    // Load the "it" catalog for it, it-IT and it-CH
    sanitizedLocale = "it";
  } else {
    // Load the "en" catalog for any other language
    sanitizedLocale = "en";
  }

  const { messages } = await import(`../locales/${sanitizedLocale}`);
  const { messages: dataTableMessages } = await import(
    `../locales/data-table-${sanitizedLocale}`
  );

  const pluralsOrdinal = new Intl.PluralRules(sanitizedLocale, {
    type: "ordinal",
  });
  const pluralsCardinal = new Intl.PluralRules(sanitizedLocale, {
    type: "cardinal",
  });

  i18n.load(sanitizedLocale, { ...dataTableMessages, ...messages });
  i18n.loadLocaleData(sanitizedLocale, {
    plurals(count: number, ordinal: boolean) {
      return (ordinal ? pluralsOrdinal : pluralsCardinal).select(count);
    },
  });
  i18n.activate(sanitizedLocale);
}

export function LocalizedDate({
  value,
  format,
}: {
  value: Date;
  format?: string;
}) {
  const {
    i18n: { locale },
  } = useLingui();

  if (typeof value === "string") {
    value = new Date(value + "Z");
  }

  return (
    <>
      {formatDate(value, format ?? "Pp", {
        locale:
          supportedDateLocales[locale.substr(0, 2)] ??
          supportedDateLocales["en"],
      })}
    </>
  );
}

export function LocalizedDistance({
  value,
  baseValue,
}: {
  value: Date;
  baseValue: Date;
}) {
  const {
    i18n: { locale },
  } = useLingui();
  return (
    <>
      {formatDistance(value, baseValue, {
        locale:
          supportedDateLocales[locale.substr(0, 2)] ??
          supportedDateLocales["en"],
      })}
    </>
  );
}
