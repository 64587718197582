import {
  AccountInfo,
  BrowserUtils,
  InteractionRequiredAuthError,
  InteractionStatus,
  NavigationClient,
  PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { UsersClient } from "../Api";
import { useDebounce } from "../hooks";
import useWindowFocus from "../hooks/useWindowFocus";
import { GESTIT_SCOPE, GRAPH_SCOPES } from "../utils/authConfig";

export type ApiAccountInfo =
  | {
      isEmployee: true;
      employeeId: string;
    }
  | {
      isEmployee: false;
      employeeId: null;
    };

export type AuthContextValue = {
  accessToken: string | null;
  account: AccountInfo | null;
  signIn: (returnUrl: string) => Promise<void>;
  signOut: () => Promise<void>;
  isAuthenticated: boolean;
  isLoading: boolean;
  isAuthorized: (policy: AuthPolicy) => boolean;
} & ApiAccountInfo;

export enum AuthPolicy {
  RequireEmployee,
  RequireAdmin,
  RequireProduction,
}

type AuthPolicyContext = ApiAccountInfo & Pick<AuthContextValue, "account">;

const authPoliciesHandlers = {
  [AuthPolicy.RequireEmployee]: (ctx: AuthPolicyContext) => ctx.isEmployee,
  [AuthPolicy.RequireAdmin]: (ctx: AuthPolicyContext) =>
    (ctx.account?.idTokenClaims as any)?.["roles"]?.some(
      (r: any) => r === "Administrator"
    ) ?? false,
  [AuthPolicy.RequireProduction]: (ctx: AuthPolicyContext) =>
    (ctx.account?.idTokenClaims as any)?.["roles"]?.some(
      (r: any) => r === "Administrator" || r === "Production"
    ) ?? false,
};

const AuthContext = createContext<AuthContextValue>({
  accessToken: null,
  account: null,
  signIn: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  isAuthorized: () => false,
  isAuthenticated: false,
  isLoading: false,
  isEmployee: false,
  employeeId: null,
});

export function useAuth() {
  return useContext(AuthContext);
}

function AuthProviderInner({ children }: any) {
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const isLoading =
    inProgress !== InteractionStatus.None ||
    (isAuthenticated && accessToken === null);
  const debouncedIsLoading = useDebounce(isLoading, 300);

  const [apiAccountInfo, setApiAccountInfo] = useState<ApiAccountInfo>({
    isEmployee: false,
    employeeId: null,
  });

  const focused = useWindowFocus();

  // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/FAQ.md#how-do-i-handle-the-redirect-flow-in-a-react-app
  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      instance.setActiveAccount(accounts[0]);

      const req = {
        scopes: [GESTIT_SCOPE],
      };

      instance
        .acquireTokenSilent(req)
        .then(async (res) => {
          var client = new UsersClient({ accessToken: res.accessToken });
          var me = (await client.getMe()).result;

          setApiAccountInfo(
            // this condition is just to appease TS
            me.isEmployee
              ? {
                  isEmployee: true,
                  employeeId: me.employeeId!,
                }
              : {
                  isEmployee: false,
                  employeeId: null,
                }
          );

          setAccessToken(res.accessToken);
        })
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(req);
          } else {
            instance.loginRedirect({
              scopes: [
                GRAPH_SCOPES.OPENID,
                GRAPH_SCOPES.PROFILE,
                GRAPH_SCOPES.USER_READ,
                GESTIT_SCOPE,
              ],
              redirectStartPage:
                window.location.pathname + window.location.search,
            });
          }
        });
    }
  }, [focused, accounts, inProgress, instance, isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        account: instance.getActiveAccount(),
        isAuthenticated,
        signOut: () =>
          instance.logoutRedirect({
            account: instance.getActiveAccount(),
            onRedirectNavigate: () => !BrowserUtils.isInIframe(),
          }),
        signIn: (returnUrl: string) => {
          return instance.loginRedirect({
            scopes: [
              GRAPH_SCOPES.OPENID,
              GRAPH_SCOPES.PROFILE,
              GRAPH_SCOPES.USER_READ,
              GESTIT_SCOPE,
            ],
            redirectStartPage: returnUrl,
          });
        },
        isLoading: debouncedIsLoading,
        isAuthorized: (policy) =>
          isAuthenticated
            ? authPoliciesHandlers[policy]({
                account: instance.getActiveAccount(),
                ...apiAccountInfo,
              })
            : false,
        ...apiAccountInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/c2bd42301d2c599d605963b80663e76789d1d27e/samples/msal-react-samples/react-router-sample/src/utils/NavigationClient.js
class ReactRouterNavigationClient extends NavigationClient {
  history: ReturnType<typeof useNavigate>;

  constructor(history: ReturnType<typeof useNavigate>) {
    super();
    this.history = history;
  }

  /**
   * Navigates to other pages within the same web application
   * You can use the useHistory hook provided by react-router-dom to take advantage of client-side routing
   * @param url
   * @param options
   */
  async navigateInternal(url: string, options: any) {
    const relativePath = url.replace(window.location.origin, "");
    if (options.noHistory) {
      this.history(relativePath);
    } else {
      this.history(relativePath);
    }

    return false;
  }
}

export function AuthProvider({
  children,
  publicClientApplication,
}: React.PropsWithChildren<{
  publicClientApplication: PublicClientApplication;
}>) {
  const history = useNavigate();
  const navigationClient = new ReactRouterNavigationClient(history);
  publicClientApplication.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={publicClientApplication}>
      <AuthProviderInner>{children}</AuthProviderInner>
    </MsalProvider>
  );
}
