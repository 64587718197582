import { useMemo } from "react";
import { GestitClientOptions } from "../Api";
import { useAuth } from "../components/AuthProvider";

export function useClient<T>(
  constructor: new (options: GestitClientOptions) => T
) {
  const { accessToken } = useAuth();
  return useMemo(
    () =>
      new constructor({
        accessToken: accessToken ?? undefined,
      }),
    [accessToken, constructor]
  );
}
