import { isDevelopement } from "./env";

export const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ") > -1;
  const msie11 = ua.indexOf("Trident/") > -1;

  // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
  // const isEdge = ua.indexOf("Edge/") > -1;

  return msie || msie11;
};

export const GRAPH_SCOPES = {
  OPENID: "openid",
  PROFILE: "profile",
  OFFLINE_ACCESS: "offline_access",
  USER_READ: "User.Read",
  MAIL_READ: "Mail.Read",
};

export const GRAPH_ENDPOINTS = {
  ME: "https://graph.microsoft.com/v1.0/me",
};

export const GESTIT_SCOPE = !isDevelopement()
  ? `${(window as any).REACT_APP_AZURE_APPLICATION_URI}/user_impersonation`
  : "https://dblcdev.onmicrosoft.com/9cc68724-7cbc-4b15-99a9-fec78c898f50/user_impersonation";

const redirectUri = !isDevelopement()
  ? `https://${(window as any).REACT_APP_BASE_HOST}`
  : "http://localhost:3000";

export const msalConfig = {
  auth: {
    clientId: !isDevelopement()
      ? (window as any).REACT_APP_AZURE_CLIENT_ID
      : "9cc68724-7cbc-4b15-99a9-fec78c898f50",
    authority: "https://login.microsoftonline.com/organizations",
    redirectUri,
    postLogoutRedirectUri: redirectUri,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: isIE(),
  },
  system: {
    navigateFrameWait: 0,
  },
};
