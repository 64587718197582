import { DefaultsClient, CompanyClient, StatisticsClient } from "./../Api";

export const commonsKeys = {
  defaults: ["defaults"] as const,
  configuration: ["configuration"] as const,
  counters: ["counters"] as const,
  countries: ["countries"] as const,
};

export const getDefaultsQuery = (
  client: DefaultsClient,
  extraKeys?: string[]
) => ({
  queryKey: !!extraKeys
    ? [...commonsKeys.defaults, ...extraKeys]
    : commonsKeys.defaults,
  queryFn: () => client.getDefaults().then((r) => r.result),
});

export const getConfigurationQuery = (client: CompanyClient) => ({
  queryKey: commonsKeys.configuration,
  queryFn: () => client.getConfiguration().then((r) => r.result),
});

export const getCountersQuery = (
  client: StatisticsClient,
  enabled?: boolean
) => ({
  queryKey: commonsKeys.counters,
  queryFn: () => client.getCounters().then((r) => r.result),
  enabled: enabled ?? true,
});
